@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

.bottom_text_col .helper_text {
  font-family: 'Poppins', sans-serif !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


.HeaderMainContainer {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.HeaderMainContainer .LogoContainer {
    height: 50px;
    cursor: pointer;
}

.HeaderMainContainer .HeaderLogo {
    object-fit: contain;
    height: 50px; 
}

.PageTitleMianCintaier{
    background: #2490F9 url(/static/media/HeaderPattern.82ba4f2c.svg);
    text-align: center;
    font-size: 60px;
    color: #fff;
    padding: 30px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
  
}
.GeneralinfoMainContainer{
    margin-bottom: 50px;

}

.GeneralinfoMainContainer .LogoContainer {
    height: 50px;
    cursor: pointer;
}

.GeneralinfoMainContainer .Logo {
    object-fit: contain;
    height: 50px;
}
.ContactDetailsMainContainer{}

.ContactDetailsMainContainer .verifiedContainer {
    padding-top: 12px;
    display: flex;
    align-items: center;
}

.ContactDetailsMainContainer .chip {
    background: #EFFBF5;
    border-radius: 4px;
    width: 90px;
    height: 32px;
    color: #56B149;
    font-size: 14px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-family: "Poppins";
    justify-content: center;
}

.ContactDetailsMainContainer .chipIcon {
    font-size: 19px !important;
    margin-right: 4px !important;
}

.ContactDetailsMainContainer .Link {
    margin-left: 16px !important;
    color: #2490F9 !important;
    font-size: 14px !important;
    font-weight: 500;
    font-family: "Poppins";
}

.ContactDetailsMainContainer .devider {
    height: 1px;
    background: #E4E8EC;
    margin-bottom: 0 !important;
}

.ContactDetailsMainContainer .LinkBlack {
    margin-left: 16px !important;
    color: #1B2121 !important;
    font-size: 14px !important;
    font-weight: 500;
    font-family: "Poppins";
}

.ContactDetailsMainContainer .vrifyButton {
    color: #2490F9 !important;
    font-size: 14px !important;
    font-weight: 500;
    font-family: "Poppins";
    border: #E4E8EC 1px solid !important;
    text-transform: none !important;
    text-transform: initial !important;
    padding: 3px 8px !important;
    border-radius: 4px !important;
}


.ContactDetailsMainContainer .modal {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ContactDetailsMainContainer .modalContainer {
    background: #fff;
    width: 540px;
    min-height: 275px;
    border-radius: 4px;
}

.ContactDetailsMainContainer .ModalHeader {
    border-bottom: #E4E8EC 1px solid;
    padding: 0px 20px;
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ContactDetailsMainContainer .ModalBody {
    padding: 32px;

}

.ContactDetailsMainContainer .ModalBody .MuiPaper-outlined{
    border: none !important;
}

.ContactDetailsMainContainer .ModalBody .phone-text-field_container{
    width:100%;
}


.ContactDetailsMainContainer .ModalTitle {
    margin: 0 !important;
    color: #676C7B;
    font-size: 14px;
    font-weight: 400px;
    font-family: "Poppins !important",
}

.ContactDetailsMainContainer .ModaClose {
    color: #676C7B;
    cursor: pointer ;

}

.ContactDetailsMainContainer .ModalFooter {
    background: #F7F9FA;
    border-top: #E4E8EC 1px solid;
    padding: 16px 20px;
    justify-content: flex-end;
    display: flex;
    border-radius: 0 0 4px 4px
}

.ContactDetailsMainContainer .ButtonConfirm{
    background: #2490F9 !important;
    color: #fff !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    text-transform: upercase !important;
    padding: 10px 20px !important;
    height: 38px !important;
    border-radius: 4px !important;
    box-shadow: none !important;
    margin-left: 8px !important;
    font-family: "Poppins !important";
}

.ContactDetailsMainContainer .ButtonClose {
    background: #fff !important;
    color: #676C7B !important;
    border: #E4E8EC 1px solid !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    text-transform: upercase !important;
    padding: 10px 20px !important;
    height: 38px !important;
    border-radius: 4px !important;
    box-shadow: none !important;
    font-family: "Poppins !important";
}

.ContactDetailsMainContainer .ModalBodyContainer .helper_text{
    position: relative;
}


.ContactDetailsMainContainer .ModalBodyContainer .bottom_text_col{
        position: relative;
}

.ContactDetailsMainContainer .ModalBodyContainer .phone-text-field_container{
        width:100%;
    }

.ContactDetailsMainContainer .ModalBodyContainer .phone-text-field_container{
        width:"100%",
}

.InviteAcceptedMainContainer {
    margin-bottom: 50px;
    text-align: center;
    padding: 30px;
    margin-bottom: 300px;

}

.InviteAcceptedMainContainer  .invitecheckIcon {
    background: #56B149 ;
    width: 114px;
    height: 114px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    margin: 0 auto;
}

.InviteAcceptedMainContainer  .invitecheckIcon svg{
    font-Size:80px;
}

.InviteAcceptedMainContainer .inviteTitle {
    color: #0B132B;
    font-size: 34px;
    font-weight: 700;
    font-family: "Poppins";
}

.InviteAcceptedMainContainer .inviteDescription {
    color: #676C7B;
    font-size: 20px; 
    font-weight: 400;
    font-family: "Poppins"
}
.StapperMainContainer{

}

.StapperMainContainer .StepperHeader{
    background: #F7F9FA;
    padding: 0 26px;
}

.StapperMainContainer .StepperHeader .MuiPaper-root{
    background-color: transparent;
    max-width: 500px;
     margin: 0 auto;
}


.StapperMainContainer .StepperHeader .MuiStepIcon-root.MuiStepIcon-active{
    color: #56B149;
    width: 29px;
}

.StapperMainContainer .StepperHeader .MuiStepIcon-root.MuiStepIcon-completed{
    color: #56B149;  
    font-size: 29px
}

.StapperMainContainer .StepperHeader .MuiStepLabel-label{
    font-size: 14px;
    font-weight: 500;
    font-family: 'Poppins' !important;
}


.StapperMainContainer .completed{
    display: inline-block;
}

.StapperMainContainer .FormBody {
    max-width: 540px;
    margin: 0 auto;
    padding: 0 30px;
    padding-bottom: 30px;
    margin-top: 32px;
    position: relative;
}


.StapperMainContainer .FormButtonBody {
    margin-top: 20px;
    border-top: #E4E8EC 1px solid;
    padding-top: 24px;
    display: flex;
    justify-content: space-between;
}

.StapperMainContainer .FormButtonBody .MuiButton-containedPrimary {
        color: #fff;
        background-color: #2490F9;
        height: 50px;
        min-width: 138px;
        font-size: 14px;
        font-weight: 500;
        font-family: 'Poppins';
        border-radius: 4px;
        padding: 0 15px;
    }

.StapperMainContainer .FormButtonBody .MuiButton-root.Mui-disabled {
     color: rgba(0, 0, 0, 0.26) !important;
}


.StapperMainContainer .FormButtonBody .buttonBack {
    border: #E4E8EC 1px solid !important;
    height: 50px;
    font-size: 14px !important;
    font-weight: 500;
    font-family: 'Poppins';
    border-radius: 4px !important;
    color: #2490F9 !important;
    padding: 0 15px !important;
}


.StapperMainContainer .FormButtonBody .buttonBack .MuiSvgIcon-root{
    font-size: 16px !important;
    margin-right: 4px;    
}









.FaqMainContainer{
    background: #FCFCFC;
    padding: 40px;
}

.FaqMainContainer .FaqInnerContainer{
    max-width: 730px;
    margin: 0 auto;
    text-align: center;
}

.FaqMainContainer .FaqInnerContainer .LogoContainer{
    height: 50px;
    cursor: pointer;
}

.FaqMainContainer .FaqInnerContainer .FAQLogo{
    object-fit: contain;
    height: 50px;
}

.FaqMainContainer .FaqInnerContainer .AccordionContainer .MuiIconButton-edgeEnd{
    margin-right:0;
    position:absolute;
    left: 0;
}

.FaqMainContainer .FaqInnerContainer .AccordionContainer .MuiExpansionPanelSummary-content{
    margin:12px 30px;
}

.FaqMainContainer .FaqInnerContainer .AccordionContainer .MuiSvgIcon-root{
    color:#56B149;
}

.FaqMainContainer .FaqInnerContainer .AccordionContainer .Mui-expanded .addIcon{
    display:none;
}

.FaqMainContainer .FaqInnerContainer .AccordionContainer .minusIcon{
    display:none;
}

.FaqMainContainer .FaqInnerContainer .AccordionContainer .Mui-expanded .minusIcon{
    display:block;
}

.FaqMainContainer .FaqInnerContainer .AccordionContainer .accTitle{
    color:#0B132B;
    font-size:16px;
    font-weight: 500;
    font-family: 'Poppins';
    letter-spacing: 0;
    text-align:left !important;
}

.FaqMainContainer .FaqInnerContainer .AccordionContainer .accDate {
    text-align: left;
    color: #676C7B !important;
    font-size: 14px !important;
    font-weight: 400;
    font-family: 'Poppins';
    letter-spacing: 0;
    line-height: 20px;
    width: 100% !important;
}

.FaqMainContainer .FaqInnerContainer .AccordionContainer .MuiExpansionPanelDetails-root{
    padding: 8px 46px 15px !important;
}

.FaqMainContainer .FaqInnerContainer .AccordionContainer  .MuiExpansionPanelSummary-root.Mui-expanded{
    min-height:48px;
}

.FaqMainContainer .FaqInnerContainer .AccordionContainer .IconContianer{
    display:inherit;
}

.FaqMainContainer .FaqInnerContainer .AccordionContainer .MuiButtonBase-root{
    display:flex !important;
    padding:0 16px;
}
.FooterMainContainer {
    background: #fff;
    padding: 40px;
}

.FooterInnerContainer {
    max-width: 1170px;
    margin: 0 auto;
}

.TitleExBold {
    font-size: 20px;
    font-weight: 800;
    color: #444444;
    margin-bottom: 20px;
}

.Title {
    font-size: 20px;
    font-weight: 600;
    color: #444444;
    margin-bottom: 20px;
}

.Text {
    font-size: 14px;
    font-weight: 400;
    color: #444444;
    margin: 0;
    margin-bottom: 10px;
}

.checkIcon {
    color: #2A8853;
    position: relative;
    top: 7px;
    left: 4px;
}

.contactIcon {
    color: #7F91A2;
    position: relative;
    top: 7px;
    right: 4px;
    font-size: 20px;
}

.LinkText {
    font-size: 14px;
    font-weight: 400;
    color: #444444 !important;
    margin: 0; 
}

.LinkText:hover {
    color: #2490F9 !important;
    text-decoration: none !important;

}

.form_root__1325X {
    
}
