.ContactDetailsMainContainer{}

.ContactDetailsMainContainer .verifiedContainer {
    padding-top: 12px;
    display: flex;
    align-items: center;
}

.ContactDetailsMainContainer .chip {
    background: #EFFBF5;
    border-radius: 4px;
    width: 90px;
    height: 32px;
    color: #56B149;
    font-size: 14px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-family: "Poppins";
    justify-content: center;
}

.ContactDetailsMainContainer .chipIcon {
    font-size: 19px !important;
    margin-right: 4px !important;
}

.ContactDetailsMainContainer .Link {
    margin-left: 16px !important;
    color: #2490F9 !important;
    font-size: 14px !important;
    font-weight: 500;
    font-family: "Poppins";
}

.ContactDetailsMainContainer .devider {
    height: 1px;
    background: #E4E8EC;
    margin-bottom: 0 !important;
}

.ContactDetailsMainContainer .LinkBlack {
    margin-left: 16px !important;
    color: #1B2121 !important;
    font-size: 14px !important;
    font-weight: 500;
    font-family: "Poppins";
}

.ContactDetailsMainContainer .vrifyButton {
    color: #2490F9 !important;
    font-size: 14px !important;
    font-weight: 500;
    font-family: "Poppins";
    border: #E4E8EC 1px solid !important;
    text-transform: initial !important;
    padding: 3px 8px !important;
    border-radius: 4px !important;
}


.ContactDetailsMainContainer .modal {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ContactDetailsMainContainer .modalContainer {
    background: #fff;
    width: 540px;
    min-height: 275px;
    border-radius: 4px;
}

.ContactDetailsMainContainer .ModalHeader {
    border-bottom: #E4E8EC 1px solid;
    padding: 0px 20px;
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ContactDetailsMainContainer .ModalBody {
    padding: 32px;

}

.ContactDetailsMainContainer .ModalBody .MuiPaper-outlined{
    border: none !important;
}

.ContactDetailsMainContainer .ModalBody .phone-text-field_container{
    width:100%;
}


.ContactDetailsMainContainer .ModalTitle {
    margin: 0 !important;
    color: #676C7B;
    font-size: 14px;
    font-weight: 400px;
    font-family: "Poppins !important",
}

.ContactDetailsMainContainer .ModaClose {
    color: #676C7B;
    cursor: pointer ;

}

.ContactDetailsMainContainer .ModalFooter {
    background: #F7F9FA;
    border-top: #E4E8EC 1px solid;
    padding: 16px 20px;
    justify-content: flex-end;
    display: flex;
    border-radius: 0 0 4px 4px
}

.ContactDetailsMainContainer .ButtonConfirm{
    background: #2490F9 !important;
    color: #fff !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    text-transform: upercase !important;
    padding: 10px 20px !important;
    height: 38px !important;
    border-radius: 4px !important;
    box-shadow: none !important;
    margin-left: 8px !important;
    font-family: "Poppins !important";
}

.ContactDetailsMainContainer .ButtonClose {
    background: #fff !important;
    color: #676C7B !important;
    border: #E4E8EC 1px solid !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    text-transform: upercase !important;
    padding: 10px 20px !important;
    height: 38px !important;
    border-radius: 4px !important;
    box-shadow: none !important;
    font-family: "Poppins !important";
}

.ContactDetailsMainContainer .ModalBodyContainer .helper_text{
    position: relative;
}


.ContactDetailsMainContainer .ModalBodyContainer .bottom_text_col{
        position: relative;
}

.ContactDetailsMainContainer .ModalBodyContainer .phone-text-field_container{
        width:100%;
    }

.ContactDetailsMainContainer .ModalBodyContainer .phone-text-field_container{
        width:"100%",
}
