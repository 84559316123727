.HeaderMainContainer {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.HeaderMainContainer .LogoContainer {
    height: 50px;
    cursor: pointer;
}

.HeaderMainContainer .HeaderLogo {
    object-fit: contain;
    height: 50px; 
}