.GeneralinfoMainContainer{
    margin-bottom: 50px;

}

.GeneralinfoMainContainer .LogoContainer {
    height: 50px;
    cursor: pointer;
}

.GeneralinfoMainContainer .Logo {
    object-fit: contain;
    height: 50px;
}