.InviteAcceptedMainContainer {
    margin-bottom: 50px;
    text-align: center;
    padding: 30px;
    margin-bottom: 300px;

}

.InviteAcceptedMainContainer  .invitecheckIcon {
    background: #56B149 ;
    width: 114px;
    height: 114px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    margin: 0 auto;
}

.InviteAcceptedMainContainer  .invitecheckIcon svg{
    font-Size:80px;
}

.InviteAcceptedMainContainer .inviteTitle {
    color: #0B132B;
    font-size: 34px;
    font-weight: 700;
    font-family: "Poppins";
}

.InviteAcceptedMainContainer .inviteDescription {
    color: #676C7B;
    font-size: 20px; 
    font-weight: 400;
    font-family: "Poppins"
}