.FooterMainContainer {
    background: #fff;
    padding: 40px;
}

.FooterInnerContainer {
    max-width: 1170px;
    margin: 0 auto;
}

.TitleExBold {
    font-size: 20px;
    font-weight: 800;
    color: #444444;
    margin-bottom: 20px;
}

.Title {
    font-size: 20px;
    font-weight: 600;
    color: #444444;
    margin-bottom: 20px;
}

.Text {
    font-size: 14px;
    font-weight: 400;
    color: #444444;
    margin: 0;
    margin-bottom: 10px;
}

.checkIcon {
    color: #2A8853;
    position: relative;
    top: 7px;
    left: 4px;
}

.contactIcon {
    color: #7F91A2;
    position: relative;
    top: 7px;
    right: 4px;
    font-size: 20px;
}

.LinkText {
    font-size: 14px;
    font-weight: 400;
    color: #444444 !important;
    margin: 0; 
}

.LinkText:hover {
    color: #2490F9 !important;
    text-decoration: none !important;

}
